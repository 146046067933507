import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation.jsx";
import UserProfile from "./userProfile.jsx";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table.jsx";
// import QcRejectFile from "./Pop-ups/qc-reject-file"

const schema = yup.object().shape({
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const QcPrSegCompletedUserList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

 
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [users, setUsersList] = useState([])
  const [roleBaseDetails, setRoleBaseDetails] = useState({});


  // useEffect(() => {
  //   if(userLogin){
  //     setState(userLogin.user.state)
  //     setDistrict(userLogin.user.district)
  //   }

  // }, []);
  

  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  
  useEffect(() => {
      async function getDistricts() {
      const url = `${process.env.REACT_APP_URL}/getdistricts`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Districts", res.data.data)
          setDistricts(
            res.data.data.map((district) => ({ label: district, value: district }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getDistricts();
  }, [state]);

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getcoordinatorsforsegcompleted`;
      const headers = reqHeader(true, true, true);
      //console.log("state, district", state, district)
      const params = {
        state:state,
        district:district,
       };

      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator, value: coordinator }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();
    
  }, [district]); 

   
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);

    async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getsegcompletedusers`;
        const headers = reqHeader(true, true, true);
        const params = {state, district, coordinator:e.target.value}
        const config = { params, headers };
        await axios
          .get(url, config)
          .then((res) => {
            setUsersList(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();

    async function getrolebasedetails() {
      const url = `${process.env.REACT_APP_URL}/getrolebasedetails`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("supervisor role", res.data.data)
          setRoleBaseDetails({"Supervisor":res.data.data.supervisor.length == 0 ? "" : res.data.data.supervisor[0].supervisorName, "TeamLead":res.data.data.teamlead.length == 0 ? "" : res.data.data.teamlead[0].teamleadName, "Manager":  res.data.data.manager.length == 0 ? "" : res.data.data.manager[0].name })
        })
          .catch((err) => {});
    }
    getrolebasedetails();

  }

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }


  const rowClicked = (mobile, name, age, gender, speakerID) => {
    //console.log("mobile, name, age, gender", mobile, name, age, gender);
    history.push({ pathname : "/qcpr-seg-completed-userfilelist",
    state: {mobile:mobile, name:name, age:age, gender:gender, speakerID:speakerID}
  })
  };



  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "speakerID",
        accessor: "speakerID",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return  !row.original.isQcPrSignedOff ? <span style={{textDecoration: "underline #22A4EE",cursor: "pointer", color:"#22A4EE"}}
          onClick={() => rowClicked(row.original.mobile, row.original.name, row.original.age, row.original.gender, row.original.speakerID)}>
          {row.original.speakerID}
        </span> : <span>{row.original.speakerID}</span>
        },
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      // {
      //   Header: "Total Duration",
      //   accessor: "recordedHours",
      //   sortType: "basic",
      //   filter: "text",
      // },
      {
        Header: "Pending Files",
        accessor: "segmentationPendingCount",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Sign Off",
        accessor: "",
        sortType: "basic",
        filter: "text",
        //fixed: "left",
        Cell: ({ value, column, row }) => {
          //console.log("row", row)
          return (
            // row.original.isQcPrSignedOff ? "" :
            <button className="btn darkGreenBtn" id={`isSegSignOff${row.original._id}`} disabled={row.original.isQcPrSignedOff && !row.original.isSegmentationSignedOff } onClick={(e) => QCPRSignOffSpeaker(row.original)}>Sign Off</button>
          );
        },
      },
   
    ],
  );


  const QCPRSignOffSpeaker = async(row) => {

    if(row.segmentationPendingCount > 0){
      toast.error("Files pending for segmentation! Please ask QC person to submit it!")
    }else{       
      const url = `${process.env.REACT_APP_URL}/updateqcprspeakersignoff`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const body = {
        mobile: row.mobile, speakerID: row.speakerID, userID: row._id
      }
      //console.log("body", body)
      const config = { headers };
      await axios
        .put(url, body, config)
        .then((res) => {
          if(res.data.success){
            toast.success("QCPR Speaker signedoff successfully!")
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
          .catch((err) => {
            toast.error(err.response.data)
          });
      }

}

    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                QCPR
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Select District</option>
                          {districts && districts.map((district)=>{
                            //console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value="">Please select Coordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                <div>
                    <label>State:</label>
                    <span className="value">{state}</span>
                  </div>
                  <div>
                    <label>District:</label>
                    <span className="value">{district}</span>
                  </div>
                  {/* <div>
                    <label>Manager Name:</label>
                    <span className="value">{roleBaseDetails.Manager}</span>
                  </div>
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{roleBaseDetails.TeamLead}</span>
                  </div> */}
                  <div>
                    <label>Supervisor Name:</label>
                    <span className="value">{roleBaseDetails.Supervisor}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  
                  {/* <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent mobileDashboard">
              <Table
                      columns={columns}
                      data={users}
                      count={users.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="users"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default QcPrSegCompletedUserList;